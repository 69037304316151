/* These styles are generated from project.scss. */

.alert-debug {
  color: black;
  background-color: white;
  border-color: #d6e9c6;
}

.alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

#container {
  display: block;
  width: 100vh;
  height: 100vh;
}

/*#root {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

#deck-gl {
  width: 100%;
  height: 100%;
}
